import React, {useEffect, useState} from "react"
import styled from "styled-components"
import {Header} from "../common/Header"
import {CategoryHeader} from "../common/CategoryHeader"
import SingleBrandTilt from "../singleBrandTilt/SingleBrandTilt"
import VolvoIcon from "../../images/CarBrands/Volvo.png"
import VolkswagenIcon from "../../images/CarBrands/Volkswagen.png"
import OpelIcon from "../../images/CarBrands/Opel.png"
import RenaultIcon from "../../images/CarBrands/Renault.png"
import CitroenIcon from "../../images/CarBrands/Citroen.png"
import BMWIcon from "../../images/CarBrands/BMW.png"
import PreviousStepButton from "../common/PreviousStepButton"
import NextStepButton from "../common/NextStepButton"
import CustomSelect from "../common/CustomSelect"
import {useDispatch, useSelector} from "react-redux"
import {fetchBrandsAction} from "../../state/brands"
import {fetchModelsAction} from "../../state/models"
import {fetchCarTypesAction} from "../../state/carTypes"
import {fetchFuelTypesAction} from "../../state/fuelTypes"
import {fetchTransmissionTypesAction} from "../../state/transmissionTypes"
import {setFormValueAction} from "../../state/form"
import {ContentWrapper} from "../common/ContentWrapper";
import {ErrorMessage, Formik} from "formik";
import {navigate} from "gatsby";
import {NavigationButtonsWrapper} from "../common/NavigationButtonsWrapper";
import PetrolImage from "../../images/FuelTypesPictograms/benzyna.svg"
import DieselImage from "../../images/FuelTypesPictograms/diesel.svg"
import HybridImage from "../../images/FuelTypesPictograms/hybryda.svg"
import ElectricImage from "../../images/FuelTypesPictograms/elektryczny.svg"
import ManualTransmission from "../../images/TransmissionTypes/skrzynia_biegow_manualna.svg"
import AutomaticTransmission from "../../images/TransmissionTypes/skrzynia_biegow_automatyczna.svg"
import {setFirstStepFirstTabFilled} from "../../state/setPagesAreFilled"
import SingleCheckbox from "../singleCheckbox/SingleCheckbox";
import Logo from "../../images/SideStepsPreview/polisonet-logo.png";

export const TabWrapper = styled.div`
	width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 30px 30px 60px;
	@media(min-width: 768px) {
		padding: 80px 34px 100px;
	}
	@media(min-width: 1024px) {
		width: 100%;
	} 
	@media(min-width: 1280px) {
		padding: 100px 30px 120px 40px;
	}
	@media(min-width: 1920px) {
		padding: 100px 43px 145px 75px;
	}
`

const LogoContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	height: 68px;
	margin-bottom: 30px;
	position: relative;
	@media(min-width: 768px) {
		display: none;
	}
`;

const LogoWrapper = styled.div`
	width: 83px;
	height: 68px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url( ${({bgImg}) => bgImg});
`;

export const GreenHint = styled.p`
	font-size: 15px;
	font-weight: ${({theme}) => theme.fontWeight.regular};
	line-height: 26px;
	color: ${({theme}) => theme.colors.formDarkGreen};
	text-align: center;
	&.tablet-hidden {
		display: none;
		@media(min-width: 1024px) {
			display: flex;
		}
	}
	@media(min-width: 768px) {
		font-size: 22px;
		line-height: 38px;
		margin-bottom: 70px;
	}
	@media(min-width: 1024px) {
		font-weight: ${({theme}) => theme.fontWeight.light};
		text-align: left;
		margin-bottom: 0;
	}
	@media(min-width: 1280px) {
    	font-size: 22px;
		line-height: 40px;
	}
`

const BrandTiltsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-top: 10px;
	flex-wrap: wrap;
	div {
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	@media(min-width: 414px) {
		justify-content: flex-start;
		div.tilt {
			&:nth-child(2n) {
				margin-right: 16px;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}
	@media(min-width: 536px) {
		div.tilt {
			&:nth-child(3n) {
				margin-right: 16px;
			}
			&:nth-child(4n) {
				margin-right: 0;
			}
		}
	}
	@media(min-width: 650px) {
		div.tilt {
			&:nth-child(4n) {
				margin-right: 16px;
			}
		}
	}
	
	@media(min-width: 768px) {
		justify-content: flex-start;
		padding: 0;
		div.tilt {
			&:nth-child(2n) {
				margin-right: 20px;
			}
			&:nth-child(3n) {
				margin-right: 20px;
			}
			&:nth-child(4n) {
				margin-right: unset;
			}
		}
	}
	@media(min-width: 945px) {
		div.tilt {
			&:nth-child(4n) {
				margin-right: 20px;
			}
		}
	}
	@media(min-width: 1024px) {
		padding-right: 35px;
		div.tilt {
			&:nth-child(4n) {
				margin-right: 20px;
			}
		}
	}
	@media(min-width: 1150px) {
		padding: 0;
	}
	@media(min-width: 1920px) {
		div.tilt {
			&:nth-child(2n) {
				margin-right: 20px;
			}
			&:nth-child(3n) {
				margin-right: 20px;
			}
			&:nth-child(4n) {
				margin-right: 20px;
			}
		}
	}
	&.fuel-types {
		@media(min-width: 768px) {
			padding: 0;
		}
		@media(min-width: 1280px) {
			justify-content: flex-start;
		}
	}
`

const DifferentBrandsSelectWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 15px;
	width: 100%;
	@media(min-width: 536px) {
		margin-top: -16px;
		width: auto;
	}
	@media(min-width: 675px) {
		width: 353px;
	}
	@media(min-width: 768px) {
		width: 340px;
		margin-top: -24px;
	}
	@media(min-width: 1024px) {
		margin-top: 0;
		width: 520px;
	}
	@media(min-width: 1150px) {
		width: 340px;
		margin-top: -15px;
	}
	@media(min-width: 1280px) {
		justify-content: flex-start;
		width: auto;
	}
	@media(min-width: 1507px) {
		margin-top: 0;
	}
	@media(min-width: 1920px) {
		margin-top: -15px;
		width: 240px;
	}
`

export const ErrorMessageText = styled.p`
	font-size: 16px;
	color: ${({theme}) => theme.colors.formDarkOrange};
	position: absolute;
`;

const FirstStepFirstTab = () => {
	const dispatch = useDispatch()
	const brandsReducer = useSelector(state => state.brands)
	const modelsReducer = useSelector(state => state.models)
	const carTypesReducer = useSelector(state => state.carTypes)
	const fuelTypesReducer = useSelector(state => state.fuelTypes)
	const transmissionTypesReducer = useSelector(state => state.transmissionTypes)
	const formReducer = useSelector(state => state.form)

	useEffect(() => {
		dispatch(fetchBrandsAction())
		dispatch(fetchFuelTypesAction())
		dispatch(fetchTransmissionTypesAction())
	}, []);

	const brandIconsArray = [BMWIcon, CitroenIcon, OpelIcon, RenaultIcon, VolkswagenIcon, VolvoIcon];
	const fuelTypesIconsArray = [PetrolImage, DieselImage, HybridImage, ElectricImage];
	const transmissionTypesIconsArray = [AutomaticTransmission, ManualTransmission];
	const brandsTiltsArray = brandsReducer.data.filter(item => item.id === 413 || item.id === 395 || item.id === 412 || item.id === 401 || item.id === 361 || item.id === 356);
	const brandsDropdownArray = brandsReducer.data.filter(item => item.id !== 413 && item.id !== 395 && item.id !== 412 && item.id !== 401 && item.id !== 361 && item.id !== 356);

	const [isLPGInstalled, toggleLPG] = useState(false);
	const handleLPG = () => {
		toggleLPG(!isLPGInstalled);
		dispatch(setFormValueAction("isLPG", !isLPGInstalled));
		dispatch(setFormValueAction("isLPGLabel", (!isLPGInstalled ? "tak" : "nie")));
	}

	return (
		<TabWrapper>
			<LogoContainer>
				<LogoWrapper bgImg={Logo}/>
			</LogoContainer>
			<Header>Znajdź najlepszą ofertę ubezpieczenia OC/AC</Header>
			<GreenHint>wypełnij formularz i porównaj ceny</GreenHint>
			<ContentWrapper>
				<Formik
					initialValues={{
						brand: formReducer.brand,
						brandLabel: formReducer.brandLabel,
						model: formReducer.model,
						modelLabel: formReducer.modelLabel,
						carType: formReducer.carType,
						carTypeLabel: formReducer.carTypeLabel,
						eurotaxCarId: formReducer.eurotaxCarId,
						productionYear: formReducer.productionYear,
						productionYearLabel: formReducer.productionYearLabel,
						minProductionYear: formReducer.minProductionYear,
						maxProductionYear: formReducer.maxProductionYear,
						fuelType: formReducer.fuelType,
						fuelTypeLabel: formReducer.fuelTypeLabel,
						isLPGLabel: formReducer.isLPGLabel,
						transmissionType: formReducer.transmissionType,
						transmissionTypeLabel: formReducer.transmissionTypeLabel,
						productionYearList: []
					}}
			        validate={values => {
				        const errors = {};
				        if (!values.brand) {
					        errors.brand = 'pole wymagane';
				        }
				        if (!values.model) {
					        errors.model = 'pole wymagane'
				        }
				        if (!values.carType) {
					        errors.carType = 'pole wymagane'
				        }
				        if (!values.productionYear) {
					        errors.productionYear = 'pole wymagane'
				        }
				        if (!values.fuelType) {
					        errors.fuelType = 'pole wymagane'
				        }
				        return errors;
			        }}
			        onSubmit={(values, {setSubmitting}) => {
				        setSubmitting(false);
				        dispatch(setFormValueAction("brand", values.brand))
				        dispatch(setFormValueAction("brandLabel", values.brandLabel))
				        dispatch(setFormValueAction("model", values.model))
				        dispatch(setFormValueAction("modelLabel", values.modelLabel))
				        dispatch(setFormValueAction("carType", values.carType))
				        dispatch(setFormValueAction("carTypeLabel", values.carTypeLabel))
				        dispatch(setFormValueAction("eurotaxCarId", values.eurotaxCarId))
				        dispatch(setFormValueAction("productionYear", values.productionYear))
				        dispatch(setFormValueAction("productionYearLabel", values.productionYearLabel))
				        dispatch(setFormValueAction("fuelType", values.fuelType))
				        dispatch(setFormValueAction("fuelTypeLabel", values.fuelTypeLabel))
				        dispatch(setFormValueAction("transmissionType", values.transmissionType))
				        dispatch(setFormValueAction("transmissionTypeLabel", values.transmissionTypeLabel))
				        navigate('/step-1-2')
				        dispatch(setFirstStepFirstTabFilled(true))
			        }}>
					{({values, errors, isSubmitting, touched, handleChange, handleBlur, handleSubmit, setFieldValue}) => (
						<form onSubmit={handleSubmit}>
							<CategoryHeader>Marka:</CategoryHeader>
							<BrandTiltsWrapper>
								{brandsTiltsArray.map(({label, value}, key) => (
									<SingleBrandTilt id={value}
									                 key={value}
									                 activeId={values.brand}
									                 brandIcon={brandIconsArray[key]}
									                 brandName={label}
									                 onClick={(val) => {
									                    dispatch(fetchModelsAction(val))
									                    setFieldValue("brand", val)
									                    setFieldValue("brandLabel", label)
										                 if(values.model !== null) {
										                    setFieldValue("model", null)
										                 }
										                 if(values.carType !== null) {
											                 setFieldValue("carType", null)
										                 }
										                 if(values.productionYear !== null) {
											                 setFieldValue("productionYear", null)
										                 }
									                 }}/>
								))}
								<DifferentBrandsSelectWrapper>
									<CustomSelect selectLabel="Marka" isNarrow={true} placeholder={"Inna"}
									              options={brandsDropdownArray}
									              isLoading={brandsReducer.loading}
									              name="brand"
									              selectedValue={values.brand}
									              onChange={(val) =>{
										              dispatch(fetchModelsAction(val.value))
										              setFieldValue("brand", val)
									                  setFieldValue("brandLabel", val.label)
										              if(values.model !== null) {
											              setFieldValue("model", null)
										              }
										              if(values.carType !== null) {
											              setFieldValue("carType", null)
										              }
										              if(values.productionYear !== null) {
											              setFieldValue("productionYear", null)
										              }
									              }}/>
								</DifferentBrandsSelectWrapper>
							</BrandTiltsWrapper>
							<ErrorMessageText><ErrorMessage name="brand"/></ErrorMessageText>
							<CustomSelect selectLabel="Model:"
							              placeholder={"Wybierz model samochodu"}
							              options={modelsReducer.data}
							              isLoading={modelsReducer.loading}
							              name="model"
							              selectedValue={values.model}
							              onChange={(val) => {
							                dispatch(fetchCarTypesAction(val.value));
							                setFieldValue("model", val)
							                setFieldValue("modelLabel", val.label)
							              }}/>
							<ErrorMessageText><ErrorMessage name="model"/></ErrorMessageText>
							<CustomSelect
								selectLabel="Wersja pojazdu:"
								placeholder={'Wybierz wersje pojazdu'}
								options={carTypesReducer.data}
								isLoading={carTypesReducer.loading}
								name="carType"
								selectedValue={values.carType}
								onChange={(val) => {
									setFieldValue('carType', val);
									setFieldValue('carTypeLabel', val.label);
									setFieldValue('eurotaxCarId', val.carId_ET)
									const ET_Year_From = parseInt(val.etProdYears.yearFrom.substring(0,4));
									const ET_Year_To = parseInt(val.etProdYears.yearTo.substring(0,4));
									setFieldValue('minProductionYear', ET_Year_From);
									setFieldValue('maxProductionYear', ET_Year_To);
									const prodListYear = [];
									let valueIndex = 1;
									for (let index = ET_Year_From; index <= ET_Year_To; index++) {
										prodListYear.push({
											value: valueIndex,
											label: index
										});
										valueIndex = valueIndex + 1;
									}
									setFieldValue('productionYearList', prodListYear);
								}}
							/>
							<ErrorMessageText><ErrorMessage name="carType"/></ErrorMessageText>
							<CustomSelect selectLabel="Rok produkcji:"
							              placeholder={"Wybierz rok produkcji"}
							              options={values.productionYearList}
							              name="productionYear"
							              selectedValue={values.productionYear}
							              onChange={(val) => {
								              setFieldValue("productionYear", val)
								              setFieldValue("productionYearLabel", val.label)
							              }}/>
							<ErrorMessageText><ErrorMessage name="productionYear"/></ErrorMessageText>
							<CategoryHeader className="fuel-type">Rodzaj paliwa:</CategoryHeader>
							<BrandTiltsWrapper className="fuel-types">
								{fuelTypesReducer.data.map(({label, value}, key) => (
									<SingleBrandTilt id={value}
									                 key={value}
									                 activeId={values.fuelType}
									                 brandIcon={fuelTypesIconsArray[key]}
									                 brandName={label}
									                 isNotBrand={true}
										             onClick={(val) => {
											             setFieldValue("fuelType", val)
											             setFieldValue("fuelTypeLabel", label)
										             }}/>
								))}
							</BrandTiltsWrapper>
							<ErrorMessageText><ErrorMessage name="fuelType"/></ErrorMessageText>
							<SingleCheckbox name="dataProcessingAccepted"
							                type="checkbox"
							                isMarginTopNone={true}
							                checked={isLPGInstalled}
							                onChange={handleLPG}>
								<div>Pojazd posiada instalację gazową</div>
							</SingleCheckbox>
							<CategoryHeader className="fuel-type">Skrzynia biegów:</CategoryHeader>
							<BrandTiltsWrapper>
								{transmissionTypesReducer.data.map(({label, value}, key) => (
									<SingleBrandTilt id={value}
									                 key={value}
									                 activeId={values.transmissionType}
									                 brandIcon={transmissionTypesIconsArray[key]}
									                 brandName={label}
									                 isNotBrand={true}
										             onClick={(val) => {
											             setFieldValue("transmissionType", val)
											             setFieldValue("transmissionTypeLabel", label)
										             }}/>
								))}
							</BrandTiltsWrapper>
							<ErrorMessageText><ErrorMessage name="transmissionType"/></ErrorMessageText>
							<NavigationButtonsWrapper>
								<PreviousStepButton isVisible={false} textBtn={"Poprzedni krok"}/>
								<NextStepButton type="submit" isVisible={true}
								                isDisabled={isSubmitting} textBtn={"DALEJ"}/>
							</NavigationButtonsWrapper>
						</form>)}
				</Formik>
			</ContentWrapper>
		</TabWrapper>
	)
}
export default FirstStepFirstTab;

