import React from "react"
import styled from "styled-components"
import { CategoryHeader } from "./CategoryHeader"
import Select from "react-select"
import Clock from "../../images/OtherAssets/clock.svg";
import {isMobile} from 'react-device-detect';

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
  margin-top: ${({ isNarrow, isFirst }) => (isNarrow || isFirst) ? "0" : "50px"};
  position: relative;
  @media(min-width: 414px) {
	width: 353px;
  }
  @media(min-width: 536px) {
  	width: ${({ isNarrow }) => isNarrow ? "230px" : "476px"};
  }
  @media(min-width: 675px) {
	width: ${({ isNarrow }) => isNarrow ? "353px" : "476px"};
  }
  @media(min-width: 768px) {
  	width: 520px;
  }	
  @media(min-width: 1280px) {
  	width: ${({ isNarrow }) => isNarrow ? "340px" : "520px"};
  	max-width: 550px;
  }
  @media(min-width: 1507px) {
  	width: 520px;
  }
  @media(min-width: 1920px) {
  	width: ${({ isNarrow }) => isNarrow ? "280px" : "550px"};
  }
  ::-webkit-scrollbar {
	  width: 0px;
	  height: 0px;
}

  textarea:focus, input:focus {
    outline: none;
  }
  & > div {
    width: 100%;
  }
`

const colorStyles = {
  control: (styles, {isDisabled}) => ({
    ...styles,
    cursor: "pointer",
    backgroundColor: isDisabled? "#F5F7F9" : "#FFFFFF",
    borderRadius: "10px",
    border: isDisabled? "1px solid white" : "1px solid #CAE6D8",
    boxShadow: "none",
    height: "50px",
    zIndex: "10",
	  "&:hover": {
    	borderColor: "#47665A"
	  },
	  "@media(min-width: 768px)" : {
		  height: "60px",
	  },
	"@media(min-width: 1920px)" : {
		minHeight: "60px",
	},
    "&>div": {
      overflow: "visible",
      justifyContent: "center"
    }
  }),
  placeholder: styles => ({
    ...styles,
    color: "#959595",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "'Source Sans Pro', sans-serif",
    left: "20px",
    top: "calc(50% - 10px)",
    transform: "unset",
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none"
  }),
	dropdownIndicator: (base, state) => ({
		...base,
		transition: 'all .2s ease',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
	}),
  indicatorsContainer: styles => ({
    ...styles,
    cursor: "pointer",
    "svg": {
      fill: "#F87044",
      width: "29px",
      height: "27px",
    },
    "@media(min-width: 768px)": {
      "svg": {
        width: "35px",
        height: "33px"
      }
    }
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: "transparent",
    boxShadow: "none"
  }),
  menuList: styles => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "10px",
    border: "1px solid #CAE6D8",
	"::-webkit-scrollbar": {
    	display: "none",
	}
  }),
  option: (styles, state) => ({
    ...styles,
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: "16px",
	lineHeight: "20px",
	fontWeight: "600",
    color: state.isSelected || state.isFocused ? "#47665A" : "#47665A",
    backgroundColor: state.isSelected || state.isFocused ? "#FDF8F7" : "transparent !important",
    textAlign: "left",
    cursor: "pointer",
	paddingLeft: state.isSelected || state.isFocused ? "24px" : "25px",
	boxSizing: "border-box",
	borderWidth: state.isSelected || state.isFocused ? "0px 1px 0px 1px" : "none",
	borderStyle: state.isSelected || state.isFocused ? "solid" : "none",
	borderColor: state.isSelected || state.isFocused ? "#F96F44" : "none",
  }),
  singleValue: styles => ({
    ...styles,
    color: "#47665A",
    fontSize: "16px;",
    fontWeight: "600",
    fontFamily: "'Source Sans Pro', sans-serif",
    left: "25px",
	marginTop: "0",
	"@media(min-width: 1920px)" : {
    	marginTop: "0",
	}
  }),
  noOptionsMessage: styles => ({
    ...styles,
    color: "#fff"
  })
}

const ImageWhiteBackground = styled.div`
	background-color: white;
	width: 22px !important;
	height: 22px;
	z-index: 1000;
	position: absolute;
	right: 16px;
	top: calc(50% + 12px);
	@media(min-width: 402px) {
		top: calc(50% + 2px);
	}
`

const ClockIcon = styled.img`
	width: 22px;
	position: relative;
`

const CustomSelect = ({ selectLabel, placeholder, isNarrow, options, isLoading, onChange, selectedValue, isFirst, isClockShown }) => {
  return (
    <SelectWrapper isFirst={isFirst} isNarrow={isNarrow}>
      {!isNarrow && <CategoryHeader>{selectLabel}</CategoryHeader>}
      <Select styles={colorStyles} placeholder={placeholder} options={options}
              value={selectedValue}
              defaultValue={selectedValue}
              isClockShown={isClockShown}
              onChange={onChange}
              isDisabled={!options || !options.length}
              isLoading={isLoading}
              isSearchable={!isMobile}
              menuPortalTarget={(typeof document !== `undefined`) ? document.querySelector("body") : null}
      />
	    {isClockShown &&  <ImageWhiteBackground>
	      <ClockIcon src={Clock}/>
        </ImageWhiteBackground> }
    </SelectWrapper>
  )
}

export default CustomSelect
