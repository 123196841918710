import React from "react"
import styled from "styled-components"

export const Header = styled.h1`
	font-size: 17px;
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	line-height: 26px;
	color: ${({theme}) => theme.colors.formDarkGreen};
	text-align: center;
	&.tablet-hidden {
		display: none;
		@media(min-width: 1024px) {
			display: flex;
		}
	}
	@media(min-width: 768px) {
		font-size: 25px;
		font-weight: ${({theme}) => theme.fontWeight.bold};
		line-height: 38px;
	}
	@media(min-width: 1024px) {
		text-align: left;
		font-weight: ${({theme}) => theme.fontWeight.semiBold};
	}
	@media(min-width: 1280px) {
		font-size: 32px;
		line-height: 40px;
	}
`;
