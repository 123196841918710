import React from "react"
import styled from "styled-components"

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	@media(min-width: 768px) {
		margin-top: 0;
	}
	@media(min-width: 1024px) {
		margin-top: 70px;
	}
`

