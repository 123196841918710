import React from "react"
import styled from "styled-components"

const TiltWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	height: calc(0.9 * (50vw - 38px));
	width: calc(50% - 8px);
	background-color: ${({ theme }) => theme.colors.formWhite};
	border-radius: 6px;
	border: 1px solid ${({ theme }) => theme.colors.formLightGreen};
	padding: 15px 10px 10px;
	margin: 0 16px 16px 0;
	max-height: 130px;
	&:hover {
		border: 1px solid ${({ theme }) => theme.colors.formDarkGreen};
	}
	&.active {
		border: 2px solid ${({ theme }) => theme.colors.formTileActiveOrange};
		padding: 14px 10px 9px;
		box-shadow: 0 3px 5px 0 rgba(0,0,0,0.09) !important;
		background-color: ${({theme}) => theme.colors.formTileActiveBackgroundOrange}
	}
	@media(min-width: 414px) {
		width: 107px;
		height: 96px;
		max-height: unset;
	}
	@media(min-width: 768px) {
		width: 160px;
		height: 150px;
		margin: 0 20px 24px 0;
		padding-top: 30px;
		&.active {
			padding: 29px 10px 9px;
		}
	}
	@media(min-width: 1920px) {
		width: 170px;
		height: 150px;
		padding: ${({NotBrand}) => NotBrand ? "30px 10px 15px" : "22px 10px 15px"};
		&.active {
			padding: ${({NotBrand}) => NotBrand ? "29px 10px 14px" : "21px 10px 14px"};
		}
	}
`

const BrandIcon = styled.img`
	width: 44px;
	margin: 0 auto;
	@media(min-width: 340px) {
		width: 52px;
	}
	@media(min-width: 375px) {
		width: 56px;
	}
	@media(min-width: 414px) {
		width: 44px;
	}
	&.not-brand {
		width: 50px;
		@media(min-width: 340px) {
			width: 52px;
		}
		@media(min-width: 375px) {
			width: 56px;
		}
		@media(min-width: 414px) {
			width: 44px;
		}
		@media(min-width: 768px) {
			width: 55px;
		}
		@media(min-width: 1920px) {
			width: 60px;
			max-height: 65px;
		}
	}
	@media(min-width: 768px) {
		width: 70px;
	}
	@media(min-width: 1920px) {
		width: 70px;
	}
`

const BrandName = styled.p`
	font-size: 14px;
	line-height: 18px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	color: ${({ theme }) => theme.colors.formDarkGreen};
	margin: 0 auto;
	@media(min-width: 768px) {
		font-size: 16px;
		line-height: 20px;
	}
`

const SingleBrandTilt = ({ brandIcon, brandName, id, activeId, onClick, isNotBrand }) => {
  return (
    <TiltWrapper NotBrand={isNotBrand} className={activeId === id ? "active tilt" : "tilt"} key={id} onClick={() => onClick(id)}>
      <BrandIcon className={isNotBrand ? 'not-brand' : ''} src={brandIcon}/>
      <BrandName>{brandName}</BrandName>
    </TiltWrapper>
  )
}

export default SingleBrandTilt
