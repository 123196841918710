import React from "react"
import styled from "styled-components"
import {navigate} from "gatsby";

const BasicButtonWrapper = styled.div`
	justify-content: center;
	align-items: center;
	display: ${({isVisible}) => isVisible ? "flex" : "none"};
	a {
		text-decoration: none;
		color: ${({theme}) => theme.colors.formOrange};
	}
`;

const BasicButtonStyled = styled.button`
	background-color: ${({theme}) => theme.colors.formTransparent};
	border: none;
	padding: 17px 0;
	text-transform: uppercase;
	cursor: pointer;
	font-size: 16px;
	font-weight: ${({theme}) => theme.fontWeight.black};
	line-height: 20px;
	color: ${({theme}) => theme.colors.formDarkGreen};
	width: 120px;
	margin-right: 10px;
	@media(min-width: 360px) {
		width: 140px;
	}
	@media(min-width: 768px) {
		margin-right: 20px;
	}
`;


const PreviousStepButton = (props) => {
	const {textBtn, to, isVisible, type} = props;
	const handleClick = () => {
		navigate(to)
	}
	return (
		<BasicButtonWrapper isVisible={isVisible} onClick={handleClick}>
			<BasicButtonStyled type={type}>
				{textBtn}
			</BasicButtonStyled>
		</BasicButtonWrapper>
	)
};

export default PreviousStepButton;
