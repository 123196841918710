import React from "react"
import styled from "styled-components"
import BoldTick from "../../images/SideStepsPreview/tick-mobile.svg";

const StyledCheckboxLabel = styled.label`

  #label-info {
    margin: 4px 0 0;
    padding-left: 40px;
    font-size: 13px;
    line-height: 17px;
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    color: ${({ theme }) => theme.colors.formDarkGreen};
    overflow: hidden;
    cursor: pointer;
    @media (min-width: 768px) {
		padding-right: 10px;
	    font-size: 15px;
    	line-height: 19px;
	}
    @media (min-width: 1024px) {
	    font-size: 12px;
    	line-height: 15px;
	}
	@media(min-width: 1920px) {
	    font-size: 15px;
    	line-height: 19px;
	}	
  }
`

const StyledCheckboxInput = styled.div`
  	margin: 25px 0 10px;
	display: flex;
	position: relative;
	outline: 0;
		
	.checkbox-tick {
		position: absolute;
		opacity: 0;
		z-index: 1;
		width: 25px;
		height: 25px;
		margin: 0;


	& + label {
		position: relative;
		cursor: pointer;
		padding: 0;
	}

	& + label:before {
		content: '';
		position: absolute;
		vertical-align: text-top;
		display: inline-block;
		width: 25px;
		height: 25px;
		cursor: pointer;
		box-sizing: border-box;
		border: ${({theme}) => "1px solid" + theme.colors.formLightGreen};
		border-radius: 3px;
		background: transparent;
		top: 0;
	}

	&:checked + label:before {
		background-color: ${({theme}) => theme.colors.formDarkOrange};
		border: none;
	}

	& + label:after  {
    content: '';
    position: absolute;
    left: 7px;
    top: 7px;
    border-radius: 3px;
    width: 12px;
    height: 12px;
    background-image: url(${BoldTick});
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0;
    transition: opacity 0.4s ease;
	}	
	&:checked + label:after {
		opacity: 1;
    }
}
`

const SingleCheckbox = (props) => {
	const { children } = props
	const newProps = { ...props, children: null }

	return (
		<StyledCheckboxInput {...newProps}>
			<input className="checkbox-tick" id='checkbox' {...newProps}/>
			<StyledCheckboxLabel htmlFor='checkbox' {...newProps}>
				<div id="label-info">{children}</div>
			</StyledCheckboxLabel>
		</StyledCheckboxInput>

	)
}

export default SingleCheckbox
